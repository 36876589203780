<template>
  <div class="addMyIdCardContainer">
    <van-nav-bar :title="$t('myIdCard.mcTxt17')"
                 left-text=""
                 left-arrow
                 @click-left="onClickLeft"
                 @click-right="onClickRight"
                 :fixed="true" />
    <div class="fixedH"
         style="height: 1.22667rem"></div>
    <div class="myIdCardContent">
      <van-form>
        <div class="van-form_list">
          <van-cell class="sqnum_cell"
                    :title="$t('myIdCard.mcTxt3')"
                    title-class="vantitcls">
            <template #default>
              <input class="inputtext"
                     type="text"
                     :placeholder="$t('module.PleaseEnter')"
                     :readonly="ischeck"
                     v-model="form.certifino" />
            </template>
          </van-cell>
          <van-cell class="sqnum_cell"
                    :title="$t('myIdCard.mcTxt4')"
                    title-class="vantitcls">
            <template #default>
              <input class="inputtext"
                     type="text"
                     :placeholder="$t('module.PleaseEnter')"
                     :readonly="ischeck"
                     v-model="form.certifiname" />
            </template>
          </van-cell>
          <van-cell class="sqnum_cell"
                    :title="$t('myIdCard.mcTxt5')"
                    title-class="vantitcls">
            <template #default>
              <input class="inputtext"
                     type="text"
                     :placeholder="$t('module.PleaseChoose')"
                     readonly
                     v-model="form.certifitype"
                     @click="getPickerData(1)" />
            </template>
          </van-cell>
          <van-cell class="sqnum_cell"
                    :title="$t('myIdCard.mcTxt6')"
                    title-class="vantitcls">
            <template #default>
              <input class="inputtext"
                     type="text"
                     :placeholder="$t('module.PleaseChoose')"
                     readonly
                     v-model="form.certifistatus"
                     @click="getPickerData(2)" />
            </template>
          </van-cell>
        </div>
        <div class="van-form_list">
          <van-cell class="sqnum_cell"
                    :title="$t('myIdCard.mcTxt7')">
            <template #default>
              <input class="inputtext"
                     type="text"
                     :placeholder="$t('module.PleaseChoose')"
                     v-model="form.senddate"
                     @click="showDateIssueCertificates = true"
                     readonly />
            </template>
          </van-cell>
          <van-popup v-if="!ischeck"
                     v-model="showDateIssueCertificates"
                     position="bottom">
            <van-datetime-picker type="date"
                                 :title="$t('module.SelectDate')"
                                 :min-date="minDate"
                                 :max-date="maxDate"
                                 @confirm="onDateIssueCertificates"
                                 @cancel="showDateIssueCertificates = false" />
          </van-popup>
          <van-cell class="sqnum_cell"
                    :title="$t('myIdCard.mcTxt8')">
            <template #default>
              <input class="inputtext"
                     type="text"
                     :placeholder="$t('module.PleaseChoose')"
                     v-model="form.validdate"
                     readonly
                     @click="showDateIssueEffective = true" />
            </template>
          </van-cell>
          <van-popup v-if="!ischeck"
                     v-model="showDateIssueEffective"
                     position="bottom">
            <van-datetime-picker type="date"
                                 :title="$t('module.SelectDate')"
                                 :min-date="minDate"
                                 :max-date="maxDate"
                                 @confirm="onDateIssueEffective"
                                 @cancel="showDateIssueEffective = false" />
          </van-popup>
          <van-cell class="sqnum_cell"
                    :title="$t('myIdCard.mcTxt9')">
            <template #default>
              <input class="inputtext"
                     type="text"
                     :placeholder="$t('module.PleaseEnter')"
                     :readonly="ischeck"
                     v-model="form.sendunit" />
            </template>
          </van-cell>
          <van-cell class="sqnum_cell"
                    :title="$t('myIdCard.mcTxt10')">
            <template #default>
              <van-switch :disabled="ischeck"
                          v-model="form.evervalid" />
            </template>
          </van-cell>
        </div>
        <div class="van-form_list">
          <van-cell class="desc_cell"
                    :value="$t('module.remark')" />
          <van-field class="desc_field"
                     v-model="form.remark"
                     rows="8"
                     autosize
                     type="textarea"
                     :readonly="ischeck"
                     :placeholder="$t('module.PleaseEnter')" />
        </div>
        <div class="van-form_list">
          <van-cell class="desc_cell btdesc_cell"
                    :value="$t('myIdCard.mcTxt11')" />
          <div class="van-form_file_Container">
            <div class="van-form_file_BoxF"
                 v-if="!ischeck">
              <van-uploader :max-count="15"
                            v-model="fileLists"
                            :after-read="afterRead"
                            :deletable="deletableStatus"
                            :disabled="!fileDisabledStatus" />
            </div>
            <div class="van-form_file_List"
                 @click="preView(val,key)"
                 v-for="(val,key) in fileList"
                 :key="key">
              <img v-lazy="val.url">
            </div>
          </div>
        </div>
        <div class="vab-form_footer"
             v-if="!ischeck">
          <van-button type="info"
                      block
                      @click="saveFormData"
                      :disabled="!fileDisabledStatus">{{$t('module.Save')}}</van-button>
        </div>
      </van-form>
    </div>
    <!-- 普通下拉选的弹窗 -->
    <van-popup v-model="showPicker"
               position="bottom"
               round
               v-if="!ischeck">
      <van-picker show-toolbar
                  :columns="columns"
                  @confirm="onConfirm"
                  @cancel="showPicker = false">
      </van-picker>
    </van-popup>
  </div>
</template>
<script>
import { getMyCertInfo, getModuleAttFileList, saveMyCertInfo, upLoadBase64File, GetFilterDropDownList } from "@api/wx.js";
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {};
import { Toast, ImagePreview } from 'vant'
export default {
  data () {
    return {
      userInfo,
      form: {
        certifino: "",
        certifiname: "",
        certifitype: "",
        certifistatus: "",
        senddate: "",
        validdate: "",
        sendunit: "",
        evervalid: false,
        remark: ""
      },
      showDateIssueCertificates: false,
      showDateIssueEffective: false,
      minDate: new Date(),
      maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + 30)),
      fileList: [],
      fileLists: [],
      ischeck: this.$route.query.ischeck == 'true' ? true : false,
      fileDisabledStatus: true,
      deletableStatus: true,
      showPicker: false,
      columns: [],
      selectType: 1
    }
  },
  methods: {
    onClickLeft () {
      this.$router.push({
        path: "/MyIdCard"
      });
    },
    getPickerData (idx) {
      this.selectType = idx;
      let listsql = "@员工证件类别";
      if (idx == 1)
      {
        listsql = "@员工证件类别";
      } else
      {
        listsql = "@证件状态";
      }
      this.columns = [];
      GetFilterDropDownList({
        moduleno: 212,
        username: this.userInfo.username,
        listsql: listsql,
      }).then((res) => {
        if (res.data.length > 0)
        {
          for (let i = 0; i < res.data.length; i++)
          {
            this.columns.push(res.data[i].dicvalue);
          }
        }
        // console.log(res)
      });
      this.showPicker = true;
    },
    onConfirm (value) {
      console.log(value, '1111111')
      if (this.selectType == 1)
      {
        this.form.certifitype = value;
      } else
      {
        this.form.certifistatus = value;
      }
      this.showPicker = false;
    },
    onClickRight () {

    },
    enterAddCardEvent () {

    },
    afterRead (file) {
      // 此时可以自行将文件上传至服务器
      console.log(file);
    },
    // 发证日期
    onDateIssueCertificates (val) {
      if (val)
      {
        let year = val.getFullYear();
        let month = val.getMonth() + 1;
        month = month < 10 ? "0" + month : month;
        let day = val.getDate();
        day = day < 10 ? "0" + day : day;
        this.form.senddate = year + "-" + month + "-" + day;
        this.showDateIssueCertificates = false;
      }
    },
    // 有效日期
    onDateIssueEffective (val) {
      if (val)
      {
        let year = val.getFullYear();
        let month = val.getMonth() + 1;
        month = month < 10 ? "0" + month : month;
        let day = val.getDate();
        day = day < 10 ? "0" + day : day;
        this.form.validdate = year + "-" + month + "-" + day;
        this.showDateIssueEffective = false;
      }
    },
    getData () {
      let params = {
        autoid: this.$route.query.autoid,
        username: this.userInfo.username
      }
      getMyCertInfo(params).then(res => {
        this.form = res.data[0];
      })
    },
    getFiledList () {
      let params = {
        moduleno: 212,
        mautoid: this.$route.query.autoid,
        username: this.userInfo.username
      }
      getModuleAttFileList(params).then(res => {
        this.fileList = res.module_atlist
      })
    },
    preView (val, key) {
      let urlArr = []
      for (let i = 0; i < this.fileList.length; i++)
      {
        urlArr.push(this.fileList[i].url)
      }
      ImagePreview({
        images: urlArr,
        startPosition: key
      });
    },
    async saveFormData () {
      if (!this.form.certifino)
      {
        Toast(this.$t('myIdCard.mcTxt12'));
        return;
      }
      if (!this.form.certifiname)
      {
        Toast(this.$t('myIdCard.mcTxt13'));
        return;
      }
      if (!this.form.certifitype)
      {
        Toast(this.$t('myIdCard.mcTxt14'));
        return;
      }
      if (!this.form.certifistatus)
      {
        Toast(this.$t('myIdCard.mcTxt15'));
        return;
      }
      let paramss = {
        autoid: this.$route.query.autoid,
        empid: this.userInfo.empid,
        username: this.userInfo.username,
        certifino: this.form.certifino,
        certifiname: this.form.certifiname,
        certifitype: this.form.certifitype,
        certifistatus: this.form.certifistatus,
        senddate: this.form.senddate,
        validdate: this.form.validdate,
        sendunit: this.form.sendunit,
        evervalid: this.form.evervalid,
        remark: this.form.remark
      }
      let res = await saveMyCertInfo(paramss);
      if (res.iserror == 0)
      {
        for (let i = 0; i < this.fileLists.length; i++)
        {
          let n = this.fileLists[i].file.type.indexOf("/");
          let fileext = this.fileLists[i].file.type.slice((n + 1));
          let fileName = this.fileLists[i].file.name;
          let params = {
            filebase64str: this.fileLists[i].content,
            upflag: 2,
            moduleno: 212,
            autoid: this.$route.query.autoid,
            uploadpath: this.userInfo.attachfilepath,
            filename: fileName.substring(0, fileName.lastIndexOf(".")),
            fileext,
            username: this.userInfo.username,
          }
          await upLoadBase64File(params).then(res => { })
        }
        Toast(this.$t('module.editSuc'));
        this.fileDisabledStatus = false;
        this.deletableStatus = false;
        this.getFiledList();
      } else
      {
        Toast(this.$t('module.editFailed'));
      }
    }
  },
  mounted () {
    this.getData();
    this.getFiledList();
  }
}
</script>
<style lang="less" scoped>
.addMyIdCardContainer {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto;
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  /deep/.van-form {
    .van-form_list {
      width: 100%;
      margin-top: 30px;
    }
    .vantitcls {
      position: relative;
      ::before {
        content: "*";
        color: red;
        position: absolute;
        top: -5px;
        left: -16px;
      }
    }
    .inputtext {
      text-align: right;
      border: none;
      padding-right: 5px;
      display: block;
      max-width: 100% !important;
      margin: 0 !important;
      margin-right: 0 !important;
      width: 100% !important;
    }
    .van-field,
    .van-cell {
      margin: 0;
      .van-cell__title {
        font-size: 32px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #000000;
      }
      .van-cell__value {
        .van-field__body {
          .van-field__control {
            font-size: 32px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
          }
        }
      }
    }
    .desc_cell {
      margin-bottom: 0;
      .van-cell__value {
        font-size: 32px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #000000;
      }
    }
    .btdesc_cell.desc_cell {
      .van-cell__value {
        color: red;
      }
    }
    .desc_field {
      margin-top: 0;
    }
    .van-form_file_Container {
      width: 100%;
      background: #fff;
      padding: 20px 0.42667rem 35px;
    }
    .van-form_file_BoxF {
      width: 100%;
      background: #fff;
      padding: 0;
    }
    .van-form_file_Box {
      border: 1px dashed #ccc;
      border-radius: 8px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .fileZxj {
        width: 147.3px;
        height: 127.62px;
      }
    }
    .van-form_file_List {
      width: 100%;
      margin-top: 15px;
      img {
        width: 100%;
      }
    }
    .vab-form_footer {
      margin-top: 80px;
      background: #fff;
      padding: 30px 0.42667rem;
    }
  }
}
</style>